<template>
  <ek-panel title="建筑面积比例">
    <div class="box">
      <div class="t-box">
        <div class="t-item" v-for="(item, index) in items" :key="index">
          <div class="text">
            <span class="title">{{ item.label }}</span>
            <span class="num">
              <count-to
                :startVal="0"
                :endVal="item.num || 0"
                :duration="2000"
                :decimals="2"
              />
              <span class="unit">{{ item.unit }}</span>
            </span>
            <!-- <span class="num">{{ item.num }} <span class="unit">万平方米</span> </span> -->
          </div>
        </div>
      </div>
      <div class="item">
        <ek-chart
          :options="options"
          :animation="true"
          :animationData="options.series[0].data"
        />
      </div>
    </div>
  </ek-panel>
</template>

<script>
import { fetchData } from '@/api'

export default {
  data() {
    return {
      items: [],
      options: {
        color: ['#0CCE70', '#00EAFF', '#D84A12', '#EF9C00'],
        title: {
          text: `建筑面积\n比例`,
          top: '44%',
          left: '49%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 18,
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = ''
            el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params.name}：</span>
                        <span class="v">${params.value}万平方米</span>
                      </div>
                    </div>`
            return el
          },
          textStyle: {
            fontSize: 18
          }
        },
        series: [
          {
            name: '建筑面积比例',
            type: 'pie',
            radius: ['36%', '70%'],
            label: {
              color: '#fff',
              formatter: `{b} {d}%`,
              fontSize: 18
            },
            data: []
          }
        ]
      }
    }
  },
  components: {},
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await fetchData('building_area')
      if (resultCode === '200' && resultData) {
        let result = resultData.content
        let seriesData = []
        let list = []
        result.map(item => {
          seriesData.push({
            value: item.acreage,
            name: item.title
          })
          list.push({
            label: item.title,
            num: item.acreage,
            unit: item.unit
          })
        })
        this.options.series[0].data = seriesData
        this.items = list
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 291px - 30px;
  padding: 15px 20px;
  @include flex();
  .t-box {
    @include flex();
    flex-wrap: wrap;
    width: 40%;
    background: url('/images/components/ov/br/r1-bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 10px 20px;

    .t-item {
      width: calc(50% - 15px);
      @include flex();
      margin-right: 15px;
      position: relative;
    }

    .t-item:nth-child(even) {
      width: 50%;
      margin-right: 0;
    }

    .t-item:nth-child(1)::after,
    .t-item:nth-child(2)::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        244deg,
        rgba(239, 156, 0, 0) 0%,
        rgba(239, 156, 0, 1) 50%,
        rgba(239, 156, 0, 0) 100%
      );
    }
  }
  .item {
    flex: 1;
  }
}

.text {
  @include flex(column);
  justify-content: center;
}
.title {
  font-size: 19px;
}
.num {
  font-size: 29px;
  color: #ef9c00;
  font-family: PangMenZhengDao;
  font-weight: bold;
  margin-top: 20px;
}
.unit {
  font-size: 16px;
}
</style>
