<template>
  <div class="container">
    <ek-total />
    <ek-legend />

    <desc-info />
  </div>
</template>

<script>
import descInfo from './components/descInfo'

export default {
  components: { descInfo }
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  position: relative;
}
</style>
