<template>
  <div class="cs-l1">
    <ek-panel title="近年发展趋势">
      <div class="box">
        <div class="tools">
          <div class="item-box">
            <div class="item firstYear">
              <span>{{ formData.firstYear.title }}全年：</span>
              <span>客流量{{ formData.firstYear.total }}万人次</span>
            </div>
            <div class="item secondYear">
              <span>{{ formData.secondYear.title }}全年：</span>
              <span>客流量{{ formData.secondYear.total }}万人次</span>
            </div>
            <div class="item thirdYear">
              <span>{{ formData.thirdYear.title }}年至今客流量：</span>
              <span>{{ formData.thirdYear.total }}万人次</span>
            </div>
          </div>
        </div>
        <div class="chart-box">
          <ek-chart
            :options="options"
            :animation="true"
            :animationData="options.xAxis.data"
          />
        </div>
      </div>
    </ek-panel>
  </div>
</template>

<script>
import * as echarts from 'echarts'
// import chartLegend from './chartLegend'

export default {
  data() {
    return {
      options: {
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = ''
            params.map(item => {
              let unit = item.seriesName == '客流量' ? '万人' : '万元'
              el = el.concat(
                `<div class="text" style="font-size:18px;margin-top:5px;">
                    <div class="left">
                      <span class="t">${item.seriesName}&nbsp&nbsp&nbsp</span>
                    </div>
                    <div class="right">
                      <span class="v">${item.value + unit}</span>
                    </div>
                  </div>`
              )
            })
            return `<div class="tooltip-box column">
                      <span class="b-t">${params[0].name}</span>
                      ${el}
                    </div>`
          }
        },
        grid: {
          top: '16%',
          left: '6.5%',
          right: '2%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: val => {
              let date = this.dayjs(val).format('MM')
              return date + '月'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          data: [
            '2021-1',
            '2021-2',
            '2021-3',
            '2021-4',
            '2021-5',
            '2021-6',
            '2021-7',
            '2021-8',
            '2021-9',
            '2021-10',
            '2021-11',
            '2021-12',
            '2022-1',
            '2022-2',
            '2022-3',
            '2022-4',
            '2022-5',
            '2022-6',
            '2022-7',
            '2022-8',
            '2022-9',
            '2022-10',
            '2022-11',
            '2022-12',
            '2023-1',
            '2023-2',
            '2023-3',
            '2023-4',
            '2023-5',
            '2023-6'
          ]
        },
        yAxis: [
          {
            name: '客流量',
            type: 'value',
            nameTextStyle: {
              color: '#fff',
              fontSize: 18,
              align: 'right'
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#2973AC',
                type: [3, 5]
              }
            },
            axisLabel: {
              color: '#fff',
              fontSize: 16
            }
          }
        ],
        series: [
          {
            name: '客流量',
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#fff'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00FCFF' // 0% 处的颜色
                },
                {
                  offset: 0.5,
                  color: '#00ff8472' // 100% 处的颜色
                },
                {
                  offset: 1,
                  color: '#142D3F' // 100% 处的颜色
                }
              ])
            },
            markArea: {
              data: []
            },
            data: []
          }
        ]
      },
      formData: {
        firstYear: {
          title: '',
          total: 0
        },
        secondYear: {
          title: '',
          total: 0
        },
        thirdYear: {
          title: '',
          total: 0
        }
      }
    }
  },
  components: {},
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await this.$axios.post(
        '/cmsv2/content/page',
        {
          needDraft: false,
          typeCode: 'pf_trend',
          size: '36'
        }
      )
      if (resultCode === '200' && resultData) {
        let seriesArr = []
        let xAxisArr = []
        resultData.content.reverse()
        resultData.content.map(item => {
          xAxisArr.push(item.title)
          seriesArr.push(item.value)
        })

        let valArr = this.groupList(seriesArr, 12)
        let groupArr = this.groupList(xAxisArr, 12)
        let markAreaData = [
          [
            {
              xAxis: groupArr[0][0],
              itemStyle: {
                color: '#87c6fe',
                opacity: 0.4
              }
            },
            {
              xAxis: groupArr[0][groupArr[0].length - 1]
            }
          ],
          [
            {
              xAxis: groupArr[0][groupArr[0].length - 1],
              itemStyle: {
                color: '#ece6b2',
                opacity: 0.4
              }
            },
            {
              xAxis: groupArr[1][groupArr[1].length - 1]
            }
          ],
          [
            {
              xAxis: groupArr[1][groupArr[1].length - 1],
              itemStyle: {
                color: '#ffbf9c',
                opacity: 0.4
              }
            },
            {
              xAxis: groupArr[2][groupArr[2].length - 1]
            }
          ]
        ]

        this.formData.firstYear.title = groupArr[0][0].substring(0, 4)
        this.formData.secondYear.title = groupArr[1][0].substring(0, 4)
        this.formData.thirdYear.title = groupArr[2][0].substring(0, 4)
        valArr.map((year, i) => {
          if (i == 0) {
            year.map(val => {
              this.formData.firstYear.total += val
            })
          } else if (i == 1) {
            year.map(val => {
              this.formData.secondYear.total += val
            })
          } else if (i == 2) {
            year.map(val => {
              this.formData.thirdYear.total += val
            })
          }
        })
        console.log(valArr, groupArr)

        this.options.series[0].markArea.data = markAreaData
        this.options.xAxis.data = xAxisArr
        this.options.series[0].data = seriesArr
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 260px;
  @include flex(column);
  .tools {
    @include flex(column);
    .item-box {
      @include flex();
      // padding: 0 20px 0 25px;
      align-items: center;
      // justify-content: space-between;
      .item {
        @include flex();
        align-items: center;
        font-size: 18px;
        padding: 10px 0;
        margin-left: 38px;
        position: relative;
      }
      .firstYear::after {
        content: '';
        position: absolute;
        left: -17px;
        width: 12px;
        height: 12px;
        background: #87c6fe;
        // border-radius: 50%;
      }
      .secondYear::after {
        content: '';
        position: absolute;
        left: -17px;
        width: 12px;
        height: 12px;
        background: #ece6b2;
        // border-radius: 50%;
      }
      .thirdYear::after {
        content: '';
        position: absolute;
        left: -17px;
        width: 12px;
        height: 12px;
        background: #ffbf9c;
        // border-radius: 50%;
      }
    }
  }
  .chart-box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
</style>
