<template>
  <ek-panel title="业态分布">
    <div class="box">
      <ek-chart
        :options="options"
        :animation="true"
        :animationData="options.series[0].data"
      />
    </div>
  </ek-panel>
</template>

<script>
import { ov_br_r2 } from '@/api/other'

export default {
  data() {
    return {
      options: {
        color: ['#0CCE70', '#EF9C00'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = ''
            params.map(item => {
              let unit = item.seriesName.substring(
                3,
                item.seriesName.length - 1
              )
              let seriesName = item.seriesName.substring(0, 2)

              // <div class="left">
              //   <span>${item.marker}</span>
              //   <span class="t">${seriesName}&nbsp&nbsp&nbsp</span>
              // </div>
              el = el.concat(
                `<div class="text" style="font-size:18px;margin-top:5px;">
                    <div class="right">
                      <span>${item.marker}</span>
                      <span class="t">${seriesName}</span>
                      <span class="v">${item.value + unit}</span>
                    </div>
                  </div>`
              )
            })
            return `<div class="tooltip-box column">
                      <span class="b-t">${params[0].name}</span>
                      ${el}
                    </div>`
          },
          textStyle: {
            fontSize: 18
          }
        },
        legend: {
          right: '5%',
          icon: 'rect',
          itemWidth: 20,
          itemHeight: 20,
          textStyle: {
            color: '#fff',
            fontSize: 18,
            padding: [0, 10]
          }
        },
        grid: {
          left: '3%',
          right: '5%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            // 网格线为虚线
            show: false
          },
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            interval: 0,
            // width: 30,
            // formatter: function(val) {
            //   var strs = val.split('') //字符串数组
            //   var str = ''
            //   for (var i = 0, s; (s = strs[i++]); ) {
            //     //遍历字符串数组
            //     str += s
            //     if (!(i % 2)) str += '\n' //按需要求余
            //   }
            //   return str
            // }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          data: ['餐饮', '零售', '休闲娱乐', '教育培训', '生活服务', '金融保险']
        },
        yAxis: [
          {
            name: '数量(家)',
            type: 'value',
            nameTextStyle: {
              color: '#fff',
              fontSize: 18,
              align: 'right'
            },
            // 不显示刻度线
            axisTick: {
              show: false
            },
            splitLine: {
              // 网格线为虚线
              show: false
            },
            axisLabel: {
              color: '#fff',
              fontSize: 16
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#2973AC',
                type: [3, 5]
              }
            }
          },
          {
            name: '面积(平方米)',
            type: 'value',
            nameTextStyle: {
              color: '#fff',
              fontSize: 18,
              align: 'left'
            },
            // 不显示刻度线
            axisTick: {
              show: false
            },
            splitLine: {
              // 网格线为虚线
              show: false
            },
            axisLabel: {
              color: '#fff',
              fontSize: 16
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#2973AC',
                type: [3, 5]
              }
            }
          }
        ],
        series: [
          {
            name: '数量(家)',
            type: 'bar',
            barWidth: '20%',
            data: []
          },
          {
            name: '面积(平方米)',
            type: 'bar',
            yAxisIndex: 1,
            barWidth: '20%',
            data: []
          }
        ]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await ov_br_r2()
      if (resultCode === '200' && resultData) {
        let list = resultData
        let xAxisData = []
        let storeNumberArr = []
        let storeAreaArr = []
        list.map(item => {
          if ('businessName' in item) {
            xAxisData.push(item.businessName)
          }
          if ('storeNumber' in item) {
            storeNumberArr.push(item.storeNumber)
          }
          if ('storeArea' in item) {
            storeAreaArr.push(item.storeArea)
          }
        })
        this.options.xAxis.data = xAxisData
        this.options.series[0].data = storeNumberArr
        this.options.series[1].data = storeAreaArr
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 290px;
}
</style>
