<template>
  <ek-chart :options="option" />
</template>

<script>
export default {
  data() {
    return {
      option: {
        color: ['#2E90E8 ', '#EF9C00'],
        title: {
          text: this.title,
          textStyle: {
            color: '#fff',
            fontSize: 18
          },
          left: '20%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let unit = this.unit.substring(3, this.unit.length - 1)
            let el = ''
            params.map(item => {
              el = el.concat(
                `<div class="text" style="font-size:18px;margin-top:5px;">
                        <div class="left">
                          <span>${item.marker}</span>
                          <span class="t">${
                            item.seriesName
                          }&nbsp&nbsp&nbsp</span>
                        </div>
                        <div class="right">
                          <span class="v">${item.value + unit}</span>
                        </div>
                      </div>`
              )
            })
            return `<div class="tooltip-box column">
                      <span class="b-t">${this.title}</span>
                      ${el}
                    </div>`
          }
        },
        legend: {
          show: false,
          selected: {
            原有: true,
            改造后: true
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff',
            fontSize: 18
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            // 网格线为虚线
            show: false
          },
          data: [this.unit]
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 14
          }
        },
        series: [
          {
            name: '原有',
            type: 'bar',
            barWidth: '20%',
            barGap: '80%',
            data: []
          },
          {
            name: '改造后',
            type: 'bar',
            barWidth: '20%',
            barGap: '80%',
            data: []
          }
        ]
      }
    }
  },
  props: {
    title: { type: String, value: '标题' },
    unit: { type: String, value: '单位' },
    legend: { type: String, value: '' },
    chartData: { type: Array, value: [] }
  },
  watch: {
    legend: {
      handler(val) {
        let legend = JSON.parse(val)
        this.option.legend.selected[legend.label] = legend.select ? true : false
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.chartData && this.initChartes()
    },
    initChartes() {
      this.option.series[0].data = [this.chartData[0]]
      this.option.series[1].data = [this.chartData[1]]
    }
  }
}
</script>

<style scoped lang="scss"></style>
