<template>
  <ek-panel title="台东概况">
    <div class="box">
      <div class="b-list">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="handleItem(item)"
        >
          <img :src="`/images/components/ov/br/l2/${index + 1}.png`" alt="" />
          <span>{{ item.label }}</span>
        </div>
      </div>
      <div class="img">
        <img :src="url" alt="" />
      </div>
    </div>
  </ek-panel>
</template>

<script>
import { fetchImageUrl } from '@/api'

export default {
  data() {
    return {
      list: [
        {
          label: '红色文化',
          type: 'wh',
          model: 'ov-br-l2',
          icon: '/images/components/ov/l1/1.png',
          desc:
            '依托街区资源，打造一系列具有商圈特色的党建载体和服务阵地。结合街区改造提升，建设一系列具有商圈特色的党建景观和精神堡垒。打造邓恩铭纪念馆，作为红色文化教育实践点，传承红色基因；打造市北区党建引领商圈治理枢纽“红立方”，开设“康米尼斯特”书房，设置青年创业空间，打造“跟党一起创业”特色品牌；打造东光路1号商圈党建服务站，探索实施台东商圈党建“四部曲”，打造“党建引领商圈基层治理”服务平台，开展系列党建活动，推动商圈长足发展。'
        },
        {
          label: '商业文化',
          type: 'wh',
          model: 'ov-br-l2',
          icon: '/images/components/ov/l1/3.png',
          desc:
            '台东自明清年间就商贾云集，是青岛民族工商业的摇篮。街区孕育了“青岛啤酒”、“利群集团”等50余个知名品牌，更有台东五金等为代表的老字号企业。引导鼓励街区企业通过自身改造升级导入新业态提升消费层级，利群集团等大企业先后投资5000余万元完成店面及亮化改造升级；引入雅诗兰黛、鲍师傅、星巴克等50余个一线品牌和区域首店，推动业态由中低端向中高端转变。举办“外贸优品汇扮靓步行街”出口产品转内销系列活动、青岛台东步行街商业创新周、非遗文化集市、2023“老字号嘉年华”暨“惠享山东消费年”等数十场消费和文化活动，推出“云逛台东”线上购物节大型直播活动，实现步行街“季季有主题、月月有活动”，不断集聚商气人气。'
        },
        {
          label: '啤酒文化',
          type: 'wh',
          model: 'ov-br-l2',
          icon: '/images/components/ov/l1/4.png',
          desc:
            '在青岛有两种泡沫，一种是大海的泡沫，一种是啤酒的泡沫，两种泡沫皆让人陶醉。登州路56号是青岛啤酒文化的发源地，青岛啤酒厂、青啤博物馆通过厂、馆融合发展的方式带动青啤文化创新发展。青岛啤酒博物馆在保护基础上，充分做到让“文物活起来”，通过展陈互动创新、服务模式创新、产品形态创新、业态规划创新，开发成为集文化历史、生产工艺、啤酒娱乐为一体的多功能旅游景点，打造出一座兼具历史和现代、文化和时尚的博物馆新形态，成为城市文化和时尚消费的聚集地，不断给消费者带来创新体验。以青啤元素为主题，以青啤厂和辽宁路商业综合体两大项目为核心，同步升级建设世界啤酒广场、啤酒花园等多个时尚体验空间，打造全球啤酒文化体验圣地。'
        }
      ],
      url: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { resultCode, resultData } = await this.$axios.post(
        '/cmsv2/content/page',
        {
          needDraft: false,
          typeCode: 'taidong_general',
          status: '1'
        }
      )
      if (resultCode === '200' && resultData) {
        if (resultData.content[0].detail_img.length > 0) {
          fetchImageUrl(resultData.content[0].detail_img).then(res => {
            this.url = res
          })
        }
      }
    },
    handleItem(item) {
      this.$eventBus.$emit('ov-br-l2', item)
      this.$eventBus.$emit('ov-br-l2ToLegend')
      this.$eventBus.$emit('ov-br-l2ToMap', item)
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 307px;
  padding: 0 30px;
  @include flex();
  align-items: center;
  // justify-content: center;
  .img {
    width: 80%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .b-list {
    flex: 1;
    height: calc(100% - 40px);
    padding: 20px 0;
    @include flex(column);
    justify-content: space-between;
    .item {
      @include flex(column);
      align-items: center;
      justify-content: center;
      // padding: 20px 0;
      cursor: pointer;
      span {
        margin-top: 5px;
      }
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
