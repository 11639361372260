<template>
  <div class="br-desc" v-if="visible" :style="{ width, height }">
    <div class="br-desc-infowindow">
      <div @click="visible = false" class="close">
        <img src="/images/map/ov/br/desc-close.png" alt="" />
      </div>
      <span class="t">简介</span>
      <span class="v">{{ detail.desc }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      detail: {},
      width: '',
      height: ''
    }
  },
  mounted() {
    this.$eventBus.$off('ov-br-legend-click')
    this.$eventBus.$on('ov-br-legend-click', () => {
      this.visible = false
    })
    this.$eventBus.$off('ov-br-l2')
    this.$eventBus.$on('ov-br-l2', item => {
      console.log(item)
      this.visible = false
      this.detail = item
      if (item.label == '红色文化') {
        this.width = '266px'
        this.height = '573px'
      } else if (item.label == '商业文化') {
        this.width = '369px'
        this.height = '573px'
      } else {
        this.width = '376px'
        this.height = '548px'
      }
      setTimeout(() => {
        this.visible = true
        this.desc = item.desc
      }, 100)
    })
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.br-desc {
  position: absolute;
  top: 360px;
  left: 0;
  pointer-events: all;
  animation: fadeInLeft-desc 1s ease;
  &-infowindow {
    position: relative;
    @include flex(column);
    font-size: 18px;
    position: relative;
    padding: 20px 12px;
    background: url('/images/map/ov/br/desc-bg.png') no-repeat;
    background-size: 100% 100%;
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 7px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .t {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
      text-align: center;
    }
    .v {
      font-size: 18px;
      letter-spacing: 4px;
      line-height: 25px;
      text-indent: 44px;
    }
  }
}

@keyframes fadeInLeft-desc {
  0% {
    -webkit-transform: translateX(-320px);
    transform: translateX(-320px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
