<template>
  <ek-panel title="百年老字号">
    <div class="box">
      <div class="i-box">
        <div class="t-header">
          <span>序号</span>
          <span>名称</span>
          <span>前身</span>
          <span>始于</span>
        </div>
        <div class="t-list">
          <!-- <swiper ref="orderSwiper" :options="swiperOptions">
            <swiper-slide v-for="(items, index) in list" :key="index">
            </swiper-slide>
          </swiper> -->
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="handleItem(item)"
          >
            <span>{{ item.number }}</span>
            <span>{{ item.title }}</span>
            <span>{{ item.origin }}</span>
            <span>{{ item.startTimeStr }}</span>
          </div>
        </div>
      </div>
    </div>
  </ek-panel>
</template>

<script>
import { fetchData } from '@/api'

export default {
  data() {
    return {
      list: [],
      swiperOptions: {
        direction: 'vertical',
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        speed: 900,
        loop: false
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.orderSwiper.$swiper
    }
  },
  mounted() {
    this.fetchData(20)
  },
  methods: {
    async fetchData(code) {
      const { resultCode, resultData } = await fetchData('td_culture', code)
      if (resultCode === '200' && resultData) {
        this.formatItemForData(resultData.content)
      }
    },
    formatItemForData(data) {
      let tempList = data.map((obj, i) => {
        obj.number = i + 1
        obj.type = this.markerType(obj.sub_type_code)
        obj.label = obj.title
        obj.lnglat = obj.location_coordinates
        return obj
      })
      this.list = tempList
    },
    handleItem(item) {
      // console.log(item)
      this.$eventBus.$emit('ov-br-r3', item)
    },
    onEnterClick() {
      this.swiper.autoplay.stop()
    },
    onLeaveClick() {
      this.swiper.autoplay.start()
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  @include flex();
  height: 290px - 20px;
  padding: 10px 30px;
}

.i-box {
  @include flex(column);
  width: 100%;
  .t-header {
    @include flex();
    align-items: center;
    height: 60px;
    background: #202738;
    span {
      font-size: 20px;
      text-align: center;
    }
  }

  .t-header > span:nth-child(1),
  .t-list > .item > span:nth-child(1) {
    flex: 1;
  }

  .t-header > span:nth-child(2),
  .t-list > .item > span:nth-child(2) {
    flex: 2;
  }
  .t-header > span:nth-child(3),
  .t-list > .item > span:nth-child(3) {
    flex: 4;
  }
  .t-header > span:nth-child(4),
  .t-list > .item > span:nth-child(4) {
    flex: 1;
  }

  .t-list {
    @include flex(column);
    flex: 1;
    .swiper-container {
      height: 220px;
      width: 100%;
    }
    .item {
      @include flex();
      align-items: center;
      height: 52px;
      background: rgba($color: #202738, $alpha: 0.8);
      cursor: pointer;
      span {
        text-align: center;
      }
    }
  }

  .t-list > .item:nth-child(odd) {
    background: rgba($color: #202738, $alpha: 0.5);
  }
}
</style>

<style lang="scss"></style>
