<template>
  <ek-panel title="四次改造">
    <div class="box">
      <div class="legend-wrapper">
        <div
          class="legend-btn"
          v-for="(item, index) in legends"
          :key="index"
          @click="handleLegend(index)"
        >
          <div
            class="legend-btn-icon"
            :style="{
              'background-color': item.select ? item.color : '#CCCCCC'
            }"
          ></div>
          <span>{{ item.label }}</span>
        </div>
      </div>
      <div class="chart-wrapper">
        <div class="item" v-for="(item, index) in items" :key="index">
          <chart
            :title="item.title"
            :unit="item.unit"
            :legend="curLegend"
            :chartData="item.chartData"
          ></chart>
        </div>
      </div>
    </div>
  </ek-panel>
</template>

<script>
import chart from './barChart'

export default {
  components: {
    chart
  },
  data() {
    return {
      items: [
        { title: '停车位提升', unit: '单位(个)', chartData: [1850, 4292] },
        {
          title: '绿地面积提升',
          unit: '单位(平方米)',
          chartData: [1593.2, 3339.4]
        },
        { title: '休憩空间提升', unit: '单位(个)', chartData: [15, 38] },
        { title: '公共卫生间提升', unit: '单位(个)', chartData: [6, 16] }
      ],
      legends: [
        { label: '原有', select: true, color: '#2E90E8' },
        { label: '改造后', select: true, color: '#EF9C00' }
      ],
      curLegend: ''
    }
  },
  mounted() {},
  methods: {
    handleLegend(i) {
      let item = this.legends[i]
      item.select = !item.select
      this.legends[i] = item
      this.curLegend = JSON.stringify(item)
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  @include flex(column);
  justify-content: space-between;
  padding: 10px;
  height: 293px - 20px;
}
.legend-wrapper {
  @include flex(row);
  justify-content: flex-end;
  margin-bottom: 10px;
  .legend-btn {
    width: 100px;
    @include flex(row);
    align-items: center;
    cursor: pointer;
    &-icon {
      width: 16px;
      height: 16px;
    }
    span {
      font-size: 18px;
      margin-left: 15px;
    }
  }
}
.chart-wrapper {
  width: calc(100% - 20px);
  height: 100%;
  @include flex(row);
  justify-content: space-between;
}
.item {
  width: 23%;
  // margin: 0 5px;
}
</style>
