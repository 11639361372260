<template>
  <div class="container">
    <l1></l1>
    <l2></l2>
    <l3></l3>
  </div>
</template>

<script>
import l1 from './components/l1'
import l2 from './components/l2'
import l3 from './components/l3'

export default {
  components: {
    l1,
    l2,
    l3
  }
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  // flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  // border:1px solid red;
  .item {
    width: 360px;
    height: 300px;
  }
}
</style>
